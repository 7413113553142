import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { differenceInDays, addHours, startOfWeek, getHours } from "date-fns";
import { getOffsetFromUTC } from "../../utils/dateUtils";

function TiTransfer({ setShowTransferModal, selectedShift, timezone, moveTi }) {
  const shiftTimeStart = selectedShift.start.toString().split(" GMT")[0];

  const formatDateForInput = (currentDate) => {
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  };

  const [newStartTime, setNewStartTime] = useState(
    formatDateForInput(selectedShift.start)
  );

  const isValid =
    Math.abs(differenceInDays(selectedShift.start, new Date(newStartTime))) < 4;

  const updateStartTime = (event) => {
    const { value } = event.target;

    const formattedValue = formatDateForInput(value);

    setNewStartTime(formattedValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newStartTimeDate = new Date(newStartTime);
    const newEndTimeDate = addHours(newStartTimeDate, 1);

    console.log(newStartTimeDate, newEndTimeDate, selectedShift);

    moveTi(newStartTime, selectedShift, timezone);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      className="d-flex flex-column align-content-center justify-content-center m-4 flex-shrink-1"
    >
      <h1>Reschedule TI Shift</h1>
      <p>
        Are you sure you want to reschedule this shift from {shiftTimeStart}?
      </p>

      <Form.Label htmlFor="tiOriginalTime">Current Start Time</Form.Label>
      <Form.Control
        type="datetime-local"
        id="tiOriginalTime"
        aria-describedby="tiOriginalTimeBlock"
        value={formatDateForInput(selectedShift.start)}
        size="lg"
        readOnly
      />
      <Form.Label htmlFor="tiNewTime">New Start Time</Form.Label>
      <Form.Control
        type="datetime-local"
        id="tiNewTime"
        aria-describedby="tiNewTimeBlock"
        placeholder="Mentor Name Or Email"
        size="lg"
        value={newStartTime}
        onChange={updateStartTime}
        isInvalid={!isValid}
      />

      <Button
        className="mt-4"
        variant="primary"
        disabled={!isValid}
        type="submit"
      >
        Update time
      </Button>
      <Button
        className="mt-4"
        variant="secondary"
        onClick={() => setShowTransferModal(undefined)}
      >
        Close
      </Button>
    </Form>
  );
}

export default TiTransfer;

// Transfer.propTypes = {
//   setShowTransferModal: PropTypes.func.isRequired,
//   search: PropTypes.func.isRequired,
//   selectedShift: PropTypes.shape({
//     title: PropTypes.string,
//     start: PropTypes.instanceOf(Date),
//     end: PropTypes.instanceOf(Date),
//     tags: PropTypes.string,
//   }).isRequired,
//   mentorNameOrEmail: PropTypes.string,
//   setMentorNameOrEmail: PropTypes.func.isRequired,
//   foundMentors: PropTypes.arrayOf(PropTypes.shape({
//     id: PropTypes.number,
//     fullname: PropTypes.string,
//   })),
//   setFoundMentors: PropTypes.func.isRequired,
//   claimShift: PropTypes.func.isRequired,
//   isValid: PropTypes.bool,
// };
