import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

function Transfer({
  setShowTransferModal,
  search,
  selectedShift,
  mentorNameOrEmail,
  setMentorNameOrEmail,
  foundMentors,
  setFoundMentors,
  claimShift,
  isValid,
}) {
  return (
    <Form onSubmit={search} noValidate className="d-flex flex-column align-content-center justify-content-center m-4 flex-shrink-1">
      <h1>Transfer Shift</h1>
      <p>{`Are you sure you want to transfer this shift from ${selectedShift.start.toString().split(' GMT')[0]} to ${selectedShift.end.toString().split(' GMT')[0]}. This shift requires ${selectedShift.tags.toLowerCase()} skills.`}</p>
      <Form.Label htmlFor="mentorNameOrEmail">Mentor Email</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          id="mentorNameOrEmail"
          aria-describedby="mentorNameEmailBlock"
          placeholder="Mentor Name Or Email"
          size="lg"
          value={mentorNameOrEmail}
          onChange={(event) => {
            setMentorNameOrEmail(event.target.value);
          }}
          isInvalid={!isValid}
        />
        <Button variant="primary" id="mentorNameEmailBlock" onClick={search}>
          Search
        </Button>
      </InputGroup>
      {foundMentors ? foundMentors.map((mentor) => (
        <div
          key={mentor.id}
          className="d-flex flex-row mt-4 mb-4 align-items-start justify-content-between"
        >
          <p>{mentor.fullname}</p>
          <div>
            <Button className="w-100" variant="primary" onClick={() => claimShift(mentor.id)}>
              Confirm
            </Button>
          </div>
        </div>
      )) : null}
      <Button
        className="mt-4"
        variant="secondary"
        onClick={() => {
          setFoundMentors(null);
          setMentorNameOrEmail('');
          setShowTransferModal(undefined);
        }}
      >
        Close
      </Button>
    </Form>
  );
}

export default Transfer;

Transfer.propTypes = {
  setShowTransferModal: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    tags: PropTypes.string,
  }).isRequired,
  mentorNameOrEmail: PropTypes.string,
  setMentorNameOrEmail: PropTypes.func.isRequired,
  foundMentors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    fullname: PropTypes.string,
  })),
  setFoundMentors: PropTypes.func.isRequired,
  claimShift: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};
