import React from "react";
import "./App.scss";

import Calendar from "./components/Calendar/Calendar";
import InviteModal from "./components/InviteModal";

import LectureModal from "./components/LectureModal";
import LectureList from "./components/LectureList";
import useConferenceData from "./hooks/useConferenceData";

function App(props) {
  const { user, is_admin: isAdmin, bosun_db_api_key } = props;
  const { programs, conferences, instructors, lectureModal, invites, lectureList } =
    useConferenceData();

  const closeModal = () => lectureModal.close();

  const parsedPrograms = programs.available.map((program) => (
    <button
      readOnly
      key={program.id}
      className={program.uuid === programs.current.uuid ? "included" : "excluded"}
      onClick={() =>
        program.uuid === programs.current.uuid
          ? programs.toggleAllCohorts()
          : programs.select(program.uuid)
      }
    >
      {program.name}
    </button>
  ));
  const parsedCohorts = programs.current?.activeCohorts.map((cohort) => (
    <button
      readOnly
      key={cohort.id}
      className={cohort.isExcluded ? "excluded" : "included"}
      onClick={() => programs.toggleCohort(cohort.uuid)}
    >
      {cohort?.name}
    </button>
  ));

  return (
    <div className="lecture-scheduler-component">
      <header>
        <nav style={{visibility:lectureList.isListActive ? "hidden" : "visible"}}>
          <div>{parsedPrograms}</div>
          <div>{parsedCohorts}</div>
        </nav>
        <section>
          {isAdmin && (
            <>
              {invites.status === "synced" && (
                <button className="synced" onClick={invites.toggleModal}>
                  Calendar invites synced up
                </button>
              )}
              {invites.status === "pending" && (
                <button className="pending" onClick={invites.toggleModal}>
                  Pending calendar invites
                </button>
              )}
              {invites.status === "error" && (
                <button className="error" onClick={invites.toggleModal}>
                  Errors with calendar invites
                </button>
              )}
            </>
          )}
          <button className="" onClick={lectureList.toggleListActive} style={{marginLeft:"0.5em"}}>
            {lectureList.isListActive ? "View All Lectures Calendar" : "View All Booked Lectures"}
          </button>
        </section>
      </header>

      <main>
        {programs.current && programs.params && !lectureList.isListActive && (
          <Calendar
            {...programs.params}
            events={conferences.calendarEvents}
            // date="2024-04-01"
            date={new Date().toDateString()}
          />
        )}
        {lectureList.isListActive && (
          <LectureList programs={programs.available} user={user} conferences={conferences.allBooked} instructors={instructors.available}/>
        )}
      </main>

      {conferences.current && (
        <LectureModal
          conference={conferences.current}
          instructors={instructors.available}
          selectedInstructorId={instructors.current}
          bookConference={conferences.book}
          deleteConference={conferences.delete}
          deleteConfAndInvite={conferences.deleteConfAndInvite}
          sendConferenceInvite={conferences.sendConferenceInvite}
          closeModal={closeModal}
          isAdmin={isAdmin}
          isProcessing={invites.isProcessing}

        />
      )}
      {invites.isModalOpen && (
        <InviteModal
          program={programs.current}
          toggleModal={invites.toggleModal}
          conferences={conferences.booked}
          instructors={instructors.available}
          sendOne={invites.sendOne}
          removeOne={invites.removeOne}
          sendAll={invites.sendAll}
          isProcessing={invites.isProcessing}
        />
      )}
    </div>
  );
}

export default App;
