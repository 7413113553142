import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchBookedConferences,
  fetchInstructors,
  updateConferenceEntry,
  deleteConferenceEntry,
  fetchCalendarConfigs,
  fetchExcludedPrograms,
} from "../helpers/dbHelpers";

import {
  wrangleInitialData,
  fetchCohorts,
  fetchPrograms,
  fetchConferencesByCohort,
} from "../helpers/conferenceHelpers";
import { isSameDay, isSameHour } from "date-fns";
import { produce } from "immer";
import {
  buildCreateOrUpdateEventPayload,
  buildDeleteEventPayload,
} from "../helpers/apiHelpers";
import useLocalStorage from "./useLocalStorage";

const exclusions = {
  programUUIDs: [
    "73b79d97-4010-453a-9481-b4493b44f479", // Career Compass
  ],
  cohortUUIDs: [
    "0eac0217-00bf-4c53-a410-8c045930f379", // West Feb 5 (Test Cohort)
  ],
};

export default function useConferenceData(initialMonth) {
  const [initialProgramName, setInitialProgramName] = useLocalStorage(
    "initialProgram",
    "Web Immersive 2.0"
  );
  const [conferenceState, setConferenceState] = useState({
    programs: [],
    instructors: [],
    bookedInstructors: [],
    selectedProgramUUID: null,
  });
  const { programs, instructors, bookedInstructors, selectedProgramUUID } =
    conferenceState;
  const currentProgram = selectedProgramUUID
    ? programs.find((program) => program.uuid === selectedProgramUUID)
    : null;
  const [conferences, setConferences] = useState([]);
  const [bookedConferences, setBookedConferences] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);

  // const [currentProgram, setCurrentProgram] = useState(null);
  const [selectedInstructorId, setSelectedInstructorId] = useState(null); //?
  const [selectedConference, setSelectedConference] = useState(null);

  const [isListActive, setIsListActive] = useState(false);
  const toggleListActive = () => setIsListActive(!isListActive);

  useEffect(() => {
    Promise.all([
      fetchPrograms(),
      fetchCohorts(),
      fetchInstructors(),
      fetchCalendarConfigs(),
      fetchExcludedPrograms(),
    ])
      .then(([programsRes, cohortsRes, instructorsRes, configRes, excludedRes]) => {
        const parsedState = wrangleInitialData({
          programs: programsRes,
          cohorts: cohortsRes,
          instructors: instructorsRes,
          programCalendarConfigs: configRes,
          excludedPrograms: excludedRes,
          initialProgramName,
        });
        setConferenceState(parsedState);
      })
      .catch((err) => console.log(err));
  }, []);

  const [calendarLoading, setCalendarLoading] = useState(false);
  useEffect(() => {
    if (currentProgram) {
      setCalendarLoading(true);
      Promise.all(
        currentProgram.activeCohorts
          .filter((conf) => !conf.isExcluded)
          .map((cohort) => fetchConferencesByCohort(cohort.uuid))
      ).then((cohortConfs) => {
        const conferenceBuffer = [];
        for (const cohort of cohortConfs) {
          if (cohort.conferences) {
            conferenceBuffer.push(...cohort.conferences);
            // const filteredConferences = cohort.conferences.filter(conf => getMonth(conf.start_datetime) === 3)
            // conferenceBuffer.push(...filteredConferences);
          } else {
            console.log("No conferences for: ", cohort);
          }
        }
        setConferences(conferenceBuffer);
        setCalendarLoading(false);
      });
    }
  }, [currentProgram]);

  useEffect(() => {
    fetchBookedConferences()
      .then(setBookedConferences)
      .catch((err) => console.log(err));
  }, []);

  const toggleExcludedCohort = (cohort_uuid) => {
    const updatedState = produce(conferenceState, (draftState) => {
      const currentProgram = draftState.programs.find(
        (program) => program.uuid === selectedProgramUUID
      );
      const cohort = currentProgram.activeCohorts.find(
        (cohort) => cohort.uuid === cohort_uuid
      );
      cohort.isExcluded = !cohort.isExcluded;
    });
    setConferenceState(updatedState);

    // const cohorts = currentProgram.activeCohorts;
    // const cohortIndex = cohorts.findIndex(
    //   (cohort) => cohort.uuid === cohort_uuid
    // );

    // const updatedCohorts = [...cohorts];
    // updatedCohorts[cohortIndex] = {
    //   ...cohorts[cohortIndex],
    //   isExcluded: !cohorts[cohortIndex].isExcluded,
    // };

    // const updatedProgram = { ...currentProgram, activeCohorts: updatedCohorts };
    // const updatedPrograms = [...conferenceState.programs];
    // const programIndex = updatedPrograms.findIndex(
    //   (program) => program.uuid === selectedProgramUUID
    // );
    // updatedPrograms[programIndex] = updatedProgram;

    // setConferenceState({
    //   ...conferenceState,
    //   programs: updatedPrograms,
    // });
  };

  const toggleAllCohorts = () => {
    const cohorts = currentProgram.activeCohorts;
    const allIncluded = cohorts.filter((cohort) => cohort.isExcluded).length === 0;

    const updatedCohorts = cohorts.map((cohort) => ({
      ...cohort,
      isExcluded: allIncluded,
    }));
    const updatedProgram = { ...currentProgram, activeCohorts: updatedCohorts };

    const updatedPrograms = [...conferenceState.programs];
    const programIndex = updatedPrograms.findIndex(
      (program) => program.uuid === selectedProgramUUID
    );
    updatedPrograms[programIndex] = updatedProgram;

    setConferenceState({
      ...conferenceState,
      programs: updatedPrograms,
    });
  };

  const selectInstructor = (instructorId) => setSelectedInstructorId(instructorId);

  const changeProgram = (programUUID) => {
    setInitialProgramName(programs.find((program) => program.uuid === programUUID).name);
    setConferenceState({
      ...conferenceState,
      selectedProgramUUID: programUUID,
    });
  };

  const [inviteLoading, setInviteLoading] = useState(false);

  const sendConferenceInvite = async (confInvite) => {
    setInviteLoading(true);
    const cohort = currentProgram.activeCohorts.find(
      (cohort) => cohort.uuid === confInvite.cohort_uuid
    );
    const instructor = instructors.find(
      (inst) => inst.mentor_clocktower_id === confInvite.mentor_clocktower_id
    );
    const { url, payload } = buildCreateOrUpdateEventPayload(
      confInvite,
      cohort,
      instructor,
      currentProgram
    );

    try {
      const res = await fetch(url, payload);
      if (res.ok) {
        const body = await res.json();

        const conf = await updateConferenceEntry({
          ...confInvite,
          google_calendar_event_id: body.event.id,
          gcal_event_sent: true,
          start_datetime: confInvite.start_datetime,
          end_datetime: confInvite.end_datetime,
        });
        const updatedBookedConferences = bookedConferences.filter(
          (conference) =>
            !(
              conference.conference_uuid === conf.conference_uuid &&
              conference.cohort_uuid === conf.cohort_uuid
            )
        );

        setBookedConferences([...updatedBookedConferences, conf]);
      }
    } catch (e) {
      console.log("OH NO", e);
    }
    setInviteLoading(false);
    setSelectedConference(null);
    setSelectedInstructorId(null);
  };

  const sendAllConferenceInvites = async (pendingConferences) => {
    let updatedBookedConferences = [...bookedConferences];
    setInviteLoading(true);
    for (const confInvite of pendingConferences) {
      const cohort = currentProgram.activeCohorts.find(
        (cohort) => cohort.uuid === confInvite.cohort_uuid
      );
      const instructor = instructors.find(
        (inst) => inst.mentor_clocktower_id === confInvite.mentor_clocktower_id
      );

      const { url, payload } = buildCreateOrUpdateEventPayload(
        confInvite,
        cohort,
        instructor,
        currentProgram
      );

      try {
        const res = await fetch(url, payload);
        if (res.ok) {
          const body = await res.json();

          const conf = await updateConferenceEntry({
            ...confInvite,
            google_calendar_event_id: body.event.id,
            gcal_event_sent: true,
            start_datetime: confInvite.start_datetime,
            end_datetime: confInvite.end_datetime,
          });
          updatedBookedConferences = updatedBookedConferences.filter(
            (conference) =>
              !(
                conference.conference_uuid === conf.conference_uuid &&
                conference.cohort_uuid === conf.cohort_uuid
              )
          );
          updatedBookedConferences.push(conf);
        }
      } catch (e) {
        console.log("OH NO", e);
      }
    }
    setBookedConferences(updatedBookedConferences);
    setInviteLoading(false);
  };

  const deleteConferenceInvite = async (confInvite) => {
    setInviteLoading(true);
    const { url, payload } = buildDeleteEventPayload(confInvite);

    try {
      const res = await fetch(url, payload);
      if (res.ok) {
        const body = await res.json();
        const conf = await deleteConferenceEntry(confInvite);
        const updatedBookedConferences = bookedConferences.filter(
          (conference) =>
            !(
              conference.conference_uuid === conf.conference_uuid &&
              conference.cohort_uuid === conf.cohort_uuid
            )
        );

        setBookedConferences(updatedBookedConferences);
      }
    } catch (e) {
      console.log("OH NO", e);
    }
    setInviteLoading(false);
    setSelectedConference(null);
    setSelectedInstructorId(null);
  };

  const bookConference = (conference, instructor) => {
    const entry = {
      name: conference.name,
      type: conference.type,
      conference_uuid: conference.conference_uuid,
      cohort_uuid: conference.cohort_uuid,
      mentor_clocktower_id: instructor.mentor_clocktower_id,
      start_datetime: conference.start_datetime,
      end_datetime: conference.end_datetime,
      google_calendar_event_id: conference.google_calendar_event_id
        ? conference.google_calendar_event_id
        : null,
      gcal_event_sent: false,
    };

    return updateConferenceEntry(entry)
      .then((conf) => {
        const updatedBookedConferences = bookedConferences.filter(
          (conference) =>
            !(
              conference.conference_uuid === conf.conference_uuid &&
              conference.cohort_uuid === conf.cohort_uuid
            )
        );

        setBookedConferences([...updatedBookedConferences, conf]);
      })
      .then(() => {
        setSelectedConference(null);
        setSelectedInstructorId(null);
      })
      .catch(console.log);
  };

  const deleteConference = (conference) => {
    deleteConferenceEntry(conference)
      .then((conf) => {
        const updatedBookedConferences = bookedConferences.filter(
          (conference) => conference.id !== conf.id
        );
        setBookedConferences(updatedBookedConferences);
      })
      .then(() => {
        setSelectedConference(null);
        setSelectedInstructorId(null);
      })
      .catch(console.log);
  };

  const selectConference = useCallback(
    (conf) => {
      const bookedInfo = bookedConferences.find(
        (booked) =>
          booked.conference_uuid === conf.conference_uuid &&
          booked.cohort_uuid === conf.cohort_uuid
      );

      const sameHourInstructors = bookedConferences
        .filter((conference) =>
          isSameHour(conf.start_datetime, conference.start_datetime)
        )
        .map((conf) => conf.mentor_clocktower_id);
      const sameDayInstructors = bookedConferences
        .filter((conference) => isSameDay(conf.start_datetime, conference.start_datetime))
        .map((conf) => conf.mentor_clocktower_id);
      const cohortInfo = currentProgram.activeCohorts.find(
        (cohort) => cohort.uuid === conf.cohort_uuid
      );
      bookedInfo && setSelectedInstructorId(bookedInfo.mentor_clocktower_id);
      setSelectedConference({
        ...conf,
        google_calendar_event_id: bookedInfo ? bookedInfo.google_calendar_event_id : null,
        cohort: cohortInfo,
        bookedInstructors: {
          day: sameDayInstructors,
          hour: sameHourInstructors,
        },
      });
    },
    [bookedConferences, conferences]
  );

  const checkMatchingConference = (conf) => {
    return bookedConferences.find(
      (booked) =>
        booked.conference_uuid === conf.conference_uuid &&
        booked.cohort_uuid === conf.cohort_uuid
    );
  };

  const calendarConferences = useMemo(
    () =>
      conferences.map((conf) => {
        const matchingConf = checkMatchingConference(conf);
        const instructor =
          matchingConf &&
          instructors.find(
            (inst) => matchingConf.mentor_clocktower_id === inst.mentor_clocktower_id
          );
        const initials = instructor && instructor.firstName[0] + instructor.lastName[0];
        const instructorName = instructor && instructor.name;
        let conferenceType;

        if (conf.type === "LecturePlan") conferenceType = "lecture";
        if (conf.type === "TestActivity") conferenceType = "test";
        if (conf.type === "Breakout") conferenceType = "breakout";
        if (conf.type === "OfficeHours") conferenceType = "officehours";

        return {
          conferenceType,
          start: conf.start_datetime,
          end: conf.end_datetime,
          name: conf.name,
          id: conf.conference_uuid + "-" + conf.cohort_uuid,
          selectLecture: () => selectConference(conf),
          isBooked: !!matchingConf,
          initials,
          instructorName,
          isWarning: false,
        };
      }),
    [conferences, bookedConferences]
  );

  const chosenParams =
    currentProgram &&
    conferenceState.programCalendarConfigs.find(
      (config) => config.program_uuid === currentProgram.uuid
    );

  const [inviteModal, setInviteModal] = useState(false);
  const toggleInviteModal = () => setInviteModal(!inviteModal);
  const programInvites = bookedConferences.filter((conf) =>
    currentProgram?.activeAndUpcomingCohortUuids.includes(conf.cohort_uuid)
  );
  const inviteStatus =
    programInvites.filter((conf) => !conf.gcal_event_sent).length === 0
      ? "synced"
      : "pending";

  return {
    programs: {
      available: programs,
      current: currentProgram,
      params: chosenParams,
      select: changeProgram,
      toggleCohort: toggleExcludedCohort,
      toggleAllCohorts,
    },
    conferences: {
      available: conferences,
      current: selectedConference,
      calendarEvents: calendarConferences,
      currentMonth,
      booked: programInvites,
      allBooked: bookedConferences,
      isLoading: calendarLoading,
      select: selectConference,
      book: bookConference,
      delete: deleteConference,
      deleteConfAndInvite: deleteConferenceInvite,
      sendConferenceInvite: sendConferenceInvite,
    },
    instructors: {
      available: instructors,
      current: selectedInstructorId,
      select: selectInstructor,
    },
    invites: {
      status: inviteStatus, // synced | pending | error
      isModalOpen: inviteModal,
      toggleModal: toggleInviteModal,
      removeOne: deleteConferenceInvite,
      sendOne: sendConferenceInvite,
      sendAll: sendAllConferenceInvites,
      isProcessing: inviteLoading,
    },
    lectureModal: {
      close: () => {
        setSelectedConference(null);
        setSelectedInstructorId(null);
      },
    },
    lectureList: {
      isListActive,
      toggleListActive,
    },
  };
}
